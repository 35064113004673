
import { useStore } from 'vuex'
import router from "@/router";
import Header from '@/components/Header.vue'
import Footter from '@/components/Footter.vue'
import { onMounted , ref } from 'vue'
import axios from 'axios'

export  default {
  components: {
        Header,
        Footter
  },
setup(){
   const store = useStore();


   return{ goToSearch, goToDash , goToLogout, goToListSearch, goToListCar , goToTabs, goToListView2, goToProceAssi}

onMounted( async () => { 
   
       await axios.get('/api/user')
      .then((response) => {
             console.log( response.data.Auth) 
   
          })
      .catch(error => {  
        if ( error.message == 'Request failed with status code 401' &&  error.response.status == '401' ) {
              localStorage.removeItem("token")
              store.dispatch('setAuth',false)
              router.push({ name: "login" })
        }
        })
  })


   function goToLogout() {
         localStorage.removeItem("token")
         store.dispatch('setAuth',false)
         router.push({ name: "login" })
    } 

    function goToSearch() {
         router.push({ name: "search" })
    } 

    function goToListSearch() {
         router.push({ name: "listsearch" })
    } 

    function goToListCar() {
         router.push({ name: "carrinho" })
    }

    function goToDash() {
         router.push({ name: "dash" })
    }  

    function goToTabs() {
         router.push({ name: "tabs" })
    }  

    function goToListView2() {
         router.push({ name: "listview2" })
    } 

    function goToProceAssi() {
         router.push({ name: "proceassi" })
    } 



},


}
