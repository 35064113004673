
import router from "@/router"

export default {
  name: "Header",

  setup()
  {
     return{ go_to_Home }
     
     function go_to_Home() {  router.push({ name: "home" }) } 
  }
}
