
import Header from '@/components/Header.vue';

export default {
  name: 'NotFound',
  components: {
        Header,
  },
  setup() {

},
}
