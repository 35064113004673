import axios from 'axios'
/* server */
axios.defaults.baseURL = 'https://crmportodivino.com.br'

//axios.defaults.baseURL = 'http://localhost:3000'

//axios.defaults.baseURL =  process.env.VUE_APP_ROOT_API

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
//axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = "http://localhost:8080"
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"