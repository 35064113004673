import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/404View.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
     meta: { auth: true }  
  },

  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound,
  
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    // meta: { auth: false }
  },
  {
    path: '/search',
    name: 'search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchView.vue'),
     meta: { auth: true } 
  },
  {
    path: '/listview',
    name: 'listview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ListView.vue'),
     meta: { auth: true } 
  },

  {
    path: '/listview2',
    name: 'listview2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ListView2.vue'),
     meta: { auth: true } 
  },
  {
    path: '/listsearch',
    name: 'listsearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ListSearchView.vue'),
     meta: { auth: true } 
  },
  {
    path: '/show',
    name: 'show',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ShowView.vue'),
    meta: { auth: true } 
  },
  {
    path: '/carrinho',
    name: 'carrinho',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CarListView.vue'),
    meta: { auth: true } 
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { auth: true }
  },
  {
    path: '/dash',
    name: 'dash',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DashView.vue'),
    meta: { auth: true }
  },


  {
    path: '/proceassi',
    name: 'proceassi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProceAssiView.vue'),
    meta: { auth: true }
  },

  {
    path: '/tabs',
    name: 'tabs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TabsView.vue'),
    meta: { auth: true }
  },
  
  {
    path: '/tabslistview',
    name: 'tabslistview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TabsListView.vue'),
    meta: { auth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {


  if ( 'auth' in to.meta &&  to.meta.auth && !localStorage.getItem('token') )
  {
      next('/login');
  } 
  else if (  'auth' in to.meta &&  !to.meta.auth && localStorage.getItem('token')   ) 
  {
      next('/');
  } 
  else
  {
      next();
  }

})



export default router
